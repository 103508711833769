import axios from "axios"

let server=axios.create({
    baseURL: "/api", //基础地址
    timeout:1000*20,
    withCredentials:true,//允许本地携带认证给服务端  cookie传递需要开启这个属性
})



export default server
