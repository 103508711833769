// 请求模块
import axios from "../utils/config"

// 1.获取表单基本信息
export const getBaseInfoApi = (id) => axios.get(`/ding/queryById?id=${id}`)
// 2.获取轨迹信息
// export const getWayApi = (id) => axios.get(`/ding/queryClockDetails?id=${id}`)
// /ding/upload
// 上传附件
export const getFujianApi = ((data)=>{
    var file = new FormData()
    Object.keys(data).forEach(key => {
        file.append(key, data[key])
    })
    return axios.post(`/ding/upload`, file, {
        headers: {
        'Content-Type': 'multipart/form-data'
        }
      });
        
})
// 提交
export const getSubmitApi = (data) => axios.post(`/ding/checkQueryProcess`,data)
// 查看审批流程
export const getLiuchengApi = (travelId) => axios.get(`/ding/queryApprovalLogByTraveId?travelId=${travelId}`)
// 出差数据  list
export const getListApi = (userId,pageNum,pageSize,userName,jobNumber,startTime,endTime,state) => axios.get(`/ding/queryFlow?userId=${userId}&pageNum=${pageNum}&pageSize=${pageSize}&userName=${userName}&jobNumber=${jobNumber}&startTime=${startTime}&endTime=${endTime}&state=${state}`)

export const getLoginApi = (code) => axios.get(`/ding/userInfo?code=${code}`)



//加班接口
export const getJiabanInfoApi = (businessId) => axios.get(`/ding/jiaban/queryByBusinessId?businessId=${businessId}`)
// 加班审批流程
export const getJiabanLiuchengApi = (businessId) => axios.get(`/ding/jiaban/queryJiaBanByBusinessId?businessId=${businessId}`)
// 加班提交
export const getJiabanSubmitApi = (data) => axios.post(`/ding/jiaban/checkJiaBanProcess`,data)
// 出差数据  list
export const getJiabanListApi = (userId,pageNum,pageSize,userName,jobNumber,startTime,endTime,state) => axios.get(`/ding/jiaban/queryAllJiaBan?userId=${userId}&pageNum=${pageNum}&pageSize=${pageSize}&userName=${userName}&jobNumber=${jobNumber}&startTime=${startTime}&endTime=${endTime}&state=${state}`)
